<template>
  <svg-icon :data="sData" original v-bind="$attrs" class="pkg-credit-card" />
</template>
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";

@Component
export default class IconElo extends Vue {
  sData: string = require("@icon/credit-card/elo.svg");
}
</script>
